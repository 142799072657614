function Footer() {
    return (
        <>
            <footer className="mt-5">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h5 className='section-title mb-1'>Disclaimer</h5>
                            <p className="fa-12">Please review this legal disclaimer carefully. Seek professional advice for any doubt. The information provided is not exhaustive and doesn't constitute part of a contractual agreement. While we strive for accuracy, the information, services, and timelines are subject to change without notice. This disclaimer doesn't obligate anyone to enter a contract or binding commitment. By using this information, you acknowledge that it doesn't contravene applicable laws, regulatory requirements, or regulations. Cryptocurrency may be unregulated in your jurisdiction. The value of cryptocurrencies may fluctuate. Profits may be subject to capital gains or other taxes applicable in your jurisdiction.</p>
                            <p className="fa-14">© {new Date().getFullYear()} babyliger.vip. All Rights Reserved.</p>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}
export default Footer;