function Buy() {
    return (
        <>
            <div className="buy-section" id="how-buy">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 text-center">
                            <h2 className='section-title mb-5'>How to Buy <span>BABYLIGER</span></h2>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div class="buy-card">
                                <div class="boder bd1">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd2">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd3">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd4">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="wrap-content mt-3">
                                    <div className="d-flex justify-content-start">
                                        <img src={require('../Assets/img/metamask.webp')} width={50} alt="" />
                                        <img src={require('../Assets/img/trustwallet.webp')} width={50} alt="" />
                                    </div>
                                    <h3 className='step-title'>Step 1: Connect Your Wallet</h3>
                                    <p>Press the connect button and choose one of the many supported wallets to connect to the website</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div class="buy-card">
                                <div class="boder bd1">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd2">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd3">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd4">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="wrap-content mt-3">
                                    <div className="d-flex justify-content-start">
                                        <img src={require('../Assets/img/binance.webp')} width={50} alt="" />
                                        <img src={require('../Assets/img/tether.webp')} width={50} alt="" />
                                    </div>
                                    <h3 className='step-title'>Step 2: Buy with BNB And USDT</h3>
                                    <p>You use either use BNB or USDT to purchase BABYLIGER tokens from this presale</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-4">
                            <div class="buy-card">
                                <div class="boder bd1">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd2">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd3">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="boder bd4">
                                    <i class="icon icon-stroke"></i>
                                </div>
                                <div class="wrap-content mt-3">
                                    <div className="d-flex justify-content-start">
                                        <img src={require('../Assets/img/binance.webp')} width={50} alt="" />
                                        <img src={require('../Assets/img/tether.webp')} width={50} alt="" />
                                    </div>
                                    <h3 className='step-title'>Step 3: Import Your BABYLIGER Tokens</h3>
                                    <p>When the buy has been successfully completed, import the token in your wallet using BABYLIGER official address after Claim.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="buy-section">
                <div className="container">

                    <div className="row align-items-center d-flex justify-content-center">
                        <div className="col-sm-12 col-md-6 col-lg-6">
                            <h2 className='section-title mb-5'>REFERRAL <span>PROGRAM</span></h2>
                            <p class="content-text">Share your referral link and get paid 5% of the ETH contributed instantly to your wallet, for every referred purchase.</p>
                            <div class="input-group copy-input mb-3">
                                <input type="text" class="form-control" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                <button class="btn btn-cust" type="button" id="button-addon2">Copy</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div> */}
        </>
    )
}
export default Buy