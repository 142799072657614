import logo from "../Assets/img/logo.png"
import { ConnectButton } from "./ConnectButton";

function Header() {
    return (
        <>
            <header className="page-header">
                <nav class="navbar navbar-expand-lg ">
                    <div class="container">
                        <a class="navbar-brand" href="#sec">
                            <img src={logo} alt="logo" width={80} height={60} />
                        </a>
                        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#collapsibleNavbar">
                            <i class="fa-solid fa-bars"></i>
                        </button>

                        <div class="collapse navbar-collapse" id="collapsibleNavbar">
                            <ul class="navbar-nav ms-auto mb-2 mb-lg-0">
                                <li class="nav-item">
                                    <a class="nav-link active" aria-current="page" href="#home">Home</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#how-buy">How to Buy</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#token">Tokonomics</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#roadmap">Roadmap</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" rel="noreferrer" target='_blank' href="https://audit.cfg.ninja/baby-liger">Audit Report</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#whitepaper">Whitepaper</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#legality">Legality</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" href="#faq">Faq's</a>
                                </li>
                                <li class="nav-item">
                                    <ConnectButton/>
                                </li>
                                
                            </ul>

                        </div>
                    </div>
                </nav>
            </header>
        </>
    )
}
export default Header;