import { Doughnut } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { EXPLORE_URL, TOKEN_ADDRESS } from '../helper/constant';
import auditbyImg from '../Assets/img/audit-by.svg'
import { trimAddress } from '../helper/functions';

ChartJS.register(ArcElement, Tooltip, Legend);

export const data = {
    // labels: ['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange'],
    // labels: ['presale', 'community rewards', 'CEX Listing', 'Stake', 'Liquidity', 'Team Allocation'],
    datasets: [
        {

            responsive: true,
            label: ['Value'],
            data: [40, 5, 10, 10, 35, 0],
            backgroundColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderColor: [
                'rgba(255, 99, 132, 1)',
                'rgba(54, 162, 235, 1)',
                'rgba(255, 206, 86, 1)',
                'rgba(75, 192, 192, 1)',
                'rgba(153, 102, 255, 1)',
                'rgba(255, 159, 64, 1)',
            ],
            borderWidth: 1,
        },
    ],
    animation: {
        animateScale: true,
        animateRotate: true
    },

};

function Tokonomics() {


    return (
        <>
            <div className="token-section" id="token">
                <div className="container">
                    <div className="row">
                        <div className="col-sm-12 col-lg-12 text-center">
                            <h2 className='section-title mb-5'><span>Token Details</span></h2>
                        </div>
                    </div>
                    <div className='align-items-center row d-flex justify-content-center'>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner'>
                                <h5 className="item--title">Token Name</h5>
                                <p className='item-desc'>BABYLIGER</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner'>
                                <h5 className="item--title">Token Symbol</h5>
                                <p className='item-desc'>BLG</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner'>
                                <h5 className="item--title">Total supply</h5>
                                <p className='item-desc'>1,000,000,000,000</p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Smart Contract</h5>
                                <p className='item-desc'>
                                    <a className='text-white' rel="noreferrer" target='_blank' href={`${EXPLORE_URL}/address/${TOKEN_ADDRESS}`}>
                                        {trimAddress(TOKEN_ADDRESS , 15)}<svg className='mb-2 mx-1' viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6"></path><polyline points="15 3 21 3 21 9"></polyline><line x1="10" y1="14" x2="21" y2="3"></line></svg>
                                    </a>
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-2 col-lg-4">
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Listing price</h5>
                                <p className='item-desc'>$0.00000758679</p>
                            </div>
                        </div>
                        <p className='text-center mt-5'>Audited by <a href='https://audit.cfg.ninja/baby-liger'><img src={auditbyImg} height="35px"  alt='audit'/></a></p>
                        {/* <div className='token-card'>
                            <div className='token-card-inner'>
                                <h5 className="item--title">Token Name</h5>
                                <p className='item-desc'>BABYLIGER</p>
                            </div>
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Token Symbol</h5>
                                <p className='item-desc'>BLG</p>
                            </div>
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Total supply</h5>
                                <p className='item-desc'>1,000,000,000,000</p>
                            </div>
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Smart Contract</h5>
                                <p className='item-desc'>
                                    <a className='text-white' rel="noreferrer" target='_blank' href={`${EXPLORE_URL}/address/${TOKEN_ADDRESS}`}>
                                        {TOKEN_ADDRESS}
                                    </a>
                                </p>
                            </div>
                            <div className='token-card-inner mt-3'>
                                <h5 className="item--title">Listing price</h5>
                                <p className='item-desc'>$0.00000758679</p>
                            </div>
                        </div> */}
                        {/* </div> */}
                        {/* <div className="col-sm-12 col-md-6 col-lg-7 mt-5 mt-md-0">
                            <div className="img-animate text-center">
                                <img src={require('../Assets/img/banner.png')} className='img-fluid h-400' alt="banner" />
                            </div>
                        </div> */}
                    </div>
                    <div className="row mt-5">
                        <div className="col-sm-12 col-lg-12 text-center">
                            <h2 className='section-title mt-5 mb-5'><span>Tokenomics</span></h2>
                        </div>
                    </div>
                    <div className='row mt-2 mt-md-0 mb-5 mb-md-0 align-items-center justify-content-center'>
                        <div className='col-sm-12 col-md-6 col-lg-6'>
                            <div className='chart-wrap'>
                                <div className='chart'>
                                    <Doughnut data={data} />
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-6 col-lg-6 mt-5 mt-md-5'>
                            <h6 className="token-title">Presale : 40%</h6>
                            <div className="progress mb-3 cust-progress" role="progressbar" aria-label="Basic example" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style={{ width: '80%' }}>
                                <div className="progress-bar" style={{ width: '100%', backgroundColor: '#ff6384' }}></div>
                            </div>
                            <h6 className="token-title">Community Rewards : 5%</h6>
                            <div className="progress mb-3 cust-progress" role="progressbar" aria-label="Basic example" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100" style={{ width: '30%' }}>
                                <div className="progress-bar" style={{ width: '100%', backgroundColor: '#36a2eb' }}></div>
                            </div>
                            <h6 className="token-title">CEX Listing : 10%</h6>
                            <div className="progress mb-3 cust-progress" role="progressbar" aria-label="Basic example" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}>
                                <div className="progress-bar" style={{ width: '100%', backgroundColor: '#ffce56' }}></div>
                            </div>
                            <h6 className="token-title">Stake : 10%</h6>
                            <div className="progress mb-3 cust-progress" role="progressbar" aria-label="Basic example" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100" style={{ width: '60%' }}>
                                <div className="progress-bar" style={{ width: '100%', backgroundColor: '#4bc0c0' }}></div>
                            </div>
                            <h6 className="token-title">Liquidity : 35%</h6>
                            <div className="progress cust-progress mb-3" role="progressbar" aria-label="Basic example" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100" style={{ width: '40%' }}>
                                <div className="progress-bar" style={{ width: '100%', backgroundColor: '#9966ff' }}></div>
                            </div>
                            <h6 className="token-title mb-3">Team Allocation : 0%</h6>
                            <h6 className="token-title">Fees Buy 4%,sell 4% for development and marketing</h6>

                        </div>
                    </div>
                </div>
            </div >
        </>
    )
}
export default Tokonomics