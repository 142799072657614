import { useEffect, useMemo, useRef, useState } from 'react';
import { useCommonStats } from '../hooks/useCommon';
import { ConnectButton } from './ConnectButton';
import { BASE_URL, BUY_CURRENCY, DEFAULT_CHAIN, PRESALE_ADDRESS, TOKEN_SYMBOL } from '../helper/constant';
import { formatPrice, getWeb3, trimAddress } from '../helper/functions';
import iconImg from '../Assets/img/logo.png';
import { useAccount, useNetwork } from 'wagmi';
import { getContract, getTokenAmount } from '../helper/contractHelper';
import Loading from './Loading';
import { useAccountStats } from '../hooks/useAccount';
import tokenAbi from '../abis/token.json';
import presaleAbi from '../abis/presale.json';
import { useEthersSigner } from '../hooks/useEthersProvider';
import { toast } from 'react-toastify';
import { ethers } from 'ethers';
import { useLocation } from 'react-router-dom';
import { CopyToClipboard } from "react-copy-to-clipboard";
import PresaleNotActive from './PresaleNotActive';
import Countdown, { zeroPad } from 'react-countdown';
import upiImg from '../Assets/img/upi.png';
import UpiModal from './UpiModal';

function HeroSection() {
    const { address } = useAccount();
    const { chain } = useNetwork();
    const [updator, setUpdator] = useState(1);
    const [loading, setLoading] = useState(false);
    const stats = useCommonStats(updator);
    const [selectedId, setSelectedId] = useState(0);
    const accStats = useAccountStats(updator, selectedId)
    const [amount, setAmount] = useState('')
    const [usdValue, setUsdValue] = useState(0);
    const selectedData = useMemo(() => {
        return BUY_CURRENCY && BUY_CURRENCY[selectedId] ? BUY_CURRENCY[selectedId] : {};
    }, [selectedId]);
    const [tokenAmount, setTokenAmount] = useState();
    const timeoutRef = useRef(null);
    const signer = useEthersSigner();
    const [refAddress, setRefAddress] = useState('');
    const search = useLocation().search;
    const [refcopy, setRefcopy] = useState(false);
    const [isClaimTab, setIsClaimTab] = useState(false);
    const [show, setShow] = useState(false);


    useEffect(() => {
        const queryChainId = new URLSearchParams(search).get('ref');
        if (queryChainId !== '') {
            setRefAddress(queryChainId);
        }

        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        async function fetch() {
            let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(BUY_CURRENCY[selectedId].address, amount, BUY_CURRENCY[selectedId].decimals) : 0
            setTokenAmount(resAmount[0])
            setUsdValue(resAmount[1])
        }
        fetch();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedId])


    const handleChangeAmount = async (e) => {
        let value = e.target.value;
        const patt = /^\d+\.{0,1}\d{0,6}$/;
        if (value === '' || value === '0') {
            setAmount(value);
            setTokenAmount(0)
        }
        else if (patt.test(value)) {
            setAmount(value);
        }

        if (timeoutRef.current) {
            clearTimeout(timeoutRef.current);
        }


        timeoutRef.current = setTimeout(async () => {
            if (patt.test(value)) {
                let resAmount = BUY_CURRENCY && BUY_CURRENCY[selectedId] ? await getTokenAmount(BUY_CURRENCY[selectedId].address, value, BUY_CURRENCY[selectedId].decimals) : 0
                setTokenAmount(resAmount[0])
                setUsdValue(resAmount[1])
            }
        }, 1000);

    };

    const handleApprove = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);

                    let tokenContract = getContract(tokenAbi, BUY_CURRENCY[selectedId].address, signer);
                    let amount1 = ethers.utils.parseEther('1000000000000').toString();

                    let tx = await tokenContract.approve(PRESALE_ADDRESS, amount1, { 'from': address });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleSubmit = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);

                    let presaleContract = getContract(presaleAbi, PRESALE_ADDRESS, signer);
                    let amount1 = ethers.utils.parseEther(amount.toString()).toString();
                    let refAddr = refAddress ? refAddress : '0x0000000000000000000000000000000000000000';
                    let valuePass = BUY_CURRENCY[selectedId].address === '0x0000000000000000000000000000000000000000' ? amount1 : 0;
                    let tx = await presaleContract.buyToken(BUY_CURRENCY[selectedId].address, amount1, refAddr, { 'from': address, value: valuePass });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const handleClaim = async () => {
        if (address) {
            if (parseInt(chain.id) === parseInt(DEFAULT_CHAIN)) {
                try {

                    setLoading(true);

                    let presaleContract = getContract(presaleAbi, PRESALE_ADDRESS, signer);
                    let tx = await presaleContract.claim({ 'from': address });
                    toast.loading('Waiting for confirmation')

                    var interval = setInterval(async function () {
                        let web3 = getWeb3();
                        var response = await web3.eth.getTransactionReceipt(tx.hash);
                        if (response != null) {
                            clearInterval(interval)
                            if (response.status === true) {
                                toast.dismiss()
                                toast.success('success ! your last transaction is success 👍');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else if (response.status === false) {
                                toast.dismiss()
                                toast.error('error ! Your last transaction is failed.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                            else {
                                toast.dismiss()
                                toast.error('error ! something went wrong.');
                                setLoading(false);
                                setUpdator(Math.random());

                            }
                        }
                    }, 5000);
                }
                catch (err) {
                    console.log(err.message)
                    toast.dismiss()
                    toast.error(err.reason ? err.reason : err.message);
                    setLoading(false);

                }
            }
            else {
                toast.dismiss()
                toast.error('Please select Binance Mainnet Network !');
                setLoading(false);

            }

        }
        else {
            toast.dismiss()
            toast.error('Please Connect Wallet!');
            setLoading(false);

        }
    }

    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return ('00d:00h:00m:00s');
        } else {
            // Render a countdown

            return (
                <span>{zeroPad(days, 2)}d:{zeroPad(hours, 2)}h:{zeroPad(minutes, 2)}m:{zeroPad(seconds, 2)}s</span>
            )
        }
    };


    const LeftTimerenderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            return (
                <div id="tiles" className="d-flex justify-content-center">
                    <div className="time-unit p-3 border rounded bg-white mx-2">
                        <span className="d-block fs-2 fw-bold">00</span>
                        <small>Days</small>
                    </div>
                    <div className="time-unit p-3 border rounded bg-white mx-2">
                        <span className="d-block fs-2 fw-bold">00</span>
                        <small>Hours</small>
                    </div>
                    <div className="time-unit p-3 border rounded bg-white mx-2">
                        <span className="d-block fs-2 fw-bold">00</span>
                        <small>Minutes</small>
                    </div>
                    <div className="time-unit p-3 border rounded bg-white mx-2">
                        <span className="d-block fs-2 fw-bold">00</span>
                        <small>Seconds</small>
                    </div>
                </div>
            );
        } else {
            // Render a countdown
            return (
                <div id="tiles" className="d-flex justify-content-center text-center text-white">
                    <div className="time-unit rounded mx-2">
                        <div className="d-block fs-4 fw-bold ">{zeroPad(days, 2)}</div>
                        <div>Days</div>
                    </div>
                    <div className="time-unit rounded mx-2">
                        <div className="d-block fs-4 fw-bold">{zeroPad(hours, 2)}</div>
                        <div>Hours</div>
                    </div>
                    <div className="time-unit rounded mx-2">
                        <div className="d-block fs-4 fw-bold">{zeroPad(minutes, 2)}</div>
                        <div>Minutes</div>
                    </div>
                    <div className="time-unit rounded mx-2">
                        <div className="d-block fs-4 fw-bold">{zeroPad(seconds, 2)}</div>
                        <div>Seconds</div>
                    </div>
                </div>
            );
        }
    };




    return (
        <>
            <div className="banner-secetion" id="home">
                <div className="container">
                    <div className="row d-flex justify-content-between align-items-center">
                        <div className="col-sm-12 col-md-12 col-lg-6">
                            <div className='row align-items-center mb-5'>
                                <div className='col-12 col-lg-5 d-flex justify-content-center'>
                                    <img src={require('../Assets/img/banner.png')} className='banner-img' alt="banner" />
                                </div>
                                <div className='col-12 col-lg-7 mt-4 text-center'>
                                    <h3 className='mt-1'>The official BABYLIGER</h3>
                                    <p>BABYLIGER is India's first meme coin registered in the central vigilance commission of India .</p>
                                    <div className='btn-groups mt-1'>
                                        <a href={`https://audit.cfg.ninja/baby-liger`} rel="noreferrer" target='_blank' className='btn btn-primary me-3'>Audit Report</a>
                                        <a href={`${BASE_URL}/WhitePaper.pdf`} rel="noreferrer" target='_blank' className='btn btn-primary style1'>white paper</a>
                                    </div>
                                </div>
                                <div className='col-12 text-center mt-5'>
                                    <div className='mt-5 ref-section'>
                                        <h2 className='section-title mb-0'>REFERRAL <span>PROGRAM</span></h2>
                                        {/* <h3 class="title_beige">Share your unique referral code with friends</h3>
                            <p class="content-text content-text_blue">100 Points + 10% Revenue</p> */}
                                        <p class="content-text mt-0">Share your referral link and earn {stats.levelRate ? stats.levelRate / 100 : 0}% of the BABYLIGER contributed for every referred purchase.</p>
                                        <div class="input-group copy-input mt-1">
                                            <input value={address ? `${window.location.href}?ref=${address}` : 'Please connect wallet'} type="text" class="form-control" placeholder="Connect wallet" aria-label="Username" aria-describedby="basic-addon1" />
                                            <CopyToClipboard text={address ? `${window.location.href}?ref=${address}` : 'Please connect wallet'} onCopy={() => {
                                                setRefcopy(true);
                                                setTimeout(() => {
                                                    setRefcopy(false);
                                                }, 2000)
                                            }}>
                                                <button class="btn btn-cust" type="button" id="button-addon2">
                                                    {!refcopy ? (

                                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>

                                                    ) : (
                                                        <svg viewBox="0 0 24 24" width="24" height="24" stroke="#fff" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="css-i6dzq1"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                                                    )}
                                                </button>
                                            </CopyToClipboard>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className='col-sm-12 col-md-12 col-lg-6 mt-5 mt-md-0'>
                            <div className='card cust-card'>
                                {stats.loading ? (
                                    <Loading />
                                ) : !stats.saleStatus ? (
                                    <PresaleNotActive />
                                ) : (
                                    <></>
                                )}

                                {stats.claimStatus || isClaimTab ? (
                                    <div className='card-body'>
                                        <h5 class="mb-5 text-center">Account : {address ? trimAddress(address) : 'Connect wallet'} </h5>
                                        <div class="row">
                                            <div class="col-6 text-center">
                                                <h5 class="mb-3">Your Total Tokens</h5>
                                                <p class="text-white">{accStats.purchasedOf ? formatPrice(accStats.purchasedOf, 15) : 0} {TOKEN_SYMBOL}</p>
                                            </div>
                                            <div class="col-6 text-center">
                                                <h5 class="mb-3">Total Claimed Tokens</h5>
                                                <p class="text-white">{accStats.claimedOf ? formatPrice(accStats.claimedOf, 15) : 0} {TOKEN_SYMBOL}</p>
                                            </div>
                                            <div class="col-6 text-center">
                                                <h5 class="mb-3">Next Claim Avalible In</h5>
                                                <p class="text-white">
                                                    {accStats.nextVest > 0 ? (
                                                        <Countdown
                                                            date={accStats.nextVest ? parseInt(accStats.nextVest) * 1000 : 0}
                                                            renderer={renderer}
                                                            key={Math.random()}
                                                        />
                                                    ) : (
                                                        <span> - - </span>
                                                    )}

                                                </p>
                                            </div>
                                            <div class="col-6 text-center">
                                                <h5 class="mb-3">Your Claimable Tokens</h5>
                                                <p class="text-white">{accStats.userAvalibleClaim ? formatPrice(accStats.userAvalibleClaim, 15) : 0} {TOKEN_SYMBOL}</p>
                                            </div>
                                            <div className='col-12'>
                                                <button onClick={() => handleClaim()} type="button" disabled={loading || accStats.userAvalibleClaim <= 0} className="btn tab-btn w-100">
                                                    {loading ? 'Loading...' : 'Claim Now'}
                                                </button>
                                            </div>
                                            {!stats.claimStatus &&
                                                <a href="#sec" onClick={() => setIsClaimTab(false)} className='claim-btn mt-3'>WANT TO PURCHASE $BABYLIGER? CLICK HERE</a>
                                            }

                                        </div>
                                    </div>
                                ) : (
                                    <div className='card-body'>
                                        <p className='card-title'>BUY IN BEFORE PRICE INCREASE!</p>
                                        <p className='mt-2 text-center text-white' style={{ fontSize: "13px" }}>LISTING PRICE = $0.00000758679</p>
                                        {/* <p className='card-subtitle'>Stage {stats.liveStageId} / {parseInt(stats.nextStageId) - 1}</p> */}
                                        <p className='card-subtitle'>Stage {stats.liveStageId} / 10</p>
                                        <div class="progress" role="progressbar" aria-label="Animated striped example" aria-valuenow={stats.totalPer} aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-bar progress-bar-striped progress-bar-animated" style={{ width: `${stats.totalPer}%` }}>
                                                {stats.nextstage && stats.nextstage[5] && stats.nextstage[5] > 0 &&
                                                    <div className=''>Next Price: ${stats.nextstage && stats.nextstage[6] ? formatPrice(stats.nextstage[6] / Math.pow(10, 18)) : 0}</div>
                                                }
                                            </div>
                                        </div>
                                        <div id="countdown" className='mt-4'>
                                            <p className='text-white text-center mb-1'>UNTIL NEXT ROUND</p>
                                            <Countdown
                                                date={stats.stages && stats.stages[2] ? parseFloat(stats.stages[2]) * 1000 : 0}
                                                renderer={LeftTimerenderer}
                                                key={Math.random()}
                                            />



                                        </div>
                                        {/* <p className='amount-raised mt-2'>Amount Raised : ${stats.stages && stats.stages[5] ? formatPrice(stats.stages[5] / Math.pow(10, 18)) : 0} / ${stats.stages && stats.stages[3] ? formatPrice(parseFloat(stats.stages[3] / Math.pow(10, 18)) * parseFloat(stats.stages[6] / Math.pow(10, 18))) : 0}</p> */}
                                        <div className='card-box'>
                                            <div className='card-divider'>
                                                <span class="card-divider-wrapper"> 1 {TOKEN_SYMBOL} = ${stats.stages && stats.stages[6] ? formatPrice(stats.stages[6] / Math.pow(10, 18)) : 0}</span>
                                            </div>
                                        </div>
                                        <div className='btn-group'>
                                            {BUY_CURRENCY && BUY_CURRENCY.length > 0 && BUY_CURRENCY.map((items, key) => (
                                                <button onClick={() => setSelectedId(key)} className={`btn ${selectedId === key ? 'tab-btn' : 'tab-btn2'}`} key={key} type='button'>
                                                    <img src={items.image} height="24px" width="24px" alt={items.name} />
                                                    <span>{items.symbol}</span>
                                                </button>
                                            ))}
                                            <button onClick={() => setShow(true)} className={`btn  tab-btn2`} type='button'>
                                                <img src={upiImg} height="24px" width="24px" alt="upi" />
                                                <span>UPI</span>
                                            </button>
                                        </div>
                                        <hr />
                                        <form className='cust-form'>
                                            <div className='d-flex justify-content-between'>
                                                <label for="exampleFormControlInput1" class="form-label">Enter amount in <b>{selectedData?.symbol}</b></label>
                                                <label for="exampleFormControlInput1" class="form-label">~${formatPrice(usdValue)}</label>
                                            </div>
                                            <div class="input-group">
                                                <input value={amount} onChange={(e) => handleChangeAmount(e)} type="text" class="form-control" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                                <span class="input-group-text" id="basic-addon1">
                                                    <img src={selectedData?.image} height="30px" width="30px" alt="bnb" />
                                                </span>
                                            </div>
                                            {address && <p className='my-0  text-white'>Balance : {accStats.tokenBal ? formatPrice(accStats.tokenBal, 5) : 0} {selectedData?.symbol}</p>}
                                            <label for="exampleFormControlInput1" class="form-label mt-3">You will get <b>BABYLIGER</b></label>
                                            <div class="input-group mb-4">
                                                <input value={tokenAmount} readOnly={true} type="text" class="form-control" placeholder="0" aria-label="Username" aria-describedby="basic-addon1" />
                                                <span class="input-group-text" id="basic-addon1">
                                                    <img src={iconImg} height="30px" width="30px" alt="logo" />
                                                </span>
                                            </div>
                                            <a href="#sec" onClick={() => setIsClaimTab(true)} className='claim-btn mb-2'>WANT TO CLAIM YOUR $BABYLIGER? CLICK HERE</a>
                                            {address ? (
                                                parseFloat(accStats.isApproved) >= amount ? (
                                                    <button onClick={() => handleSubmit()} type="button" disabled={!stats.saleStatus || loading} className="btn tab-btn w-100">
                                                        {loading ? 'Loading...' : 'Buy Now'}
                                                    </button>
                                                ) : (
                                                    <button onClick={() => handleApprove()} type="button" disabled={!stats.saleStatus || loading} className="btn tab-btn w-100">

                                                        {loading ? 'Loading...' : 'Approve'}
                                                    </button>
                                                )

                                            ) : (
                                                <ConnectButton />
                                            )}

                                        </form>
                                    </div>
                                )}


                            </div>
                        </div>
                    </div>
                </div>
                <div class="banner-scroll-down active">
                    <a href="#how-buy" class="section-link">
                        <span></span>
                        <span></span>
                        <span></span>
                    </a>
                </div>
            </div>
            <UpiModal show={show} setShow={setShow} />
        </>
    )
}
export default HeroSection;