import { bsc } from "wagmi/chains";
import bnbImg from '../Assets/img/binance-coin.png';
import usdtImg from '../Assets/img/usdt.png';

export const FAQS = [
    {
        question: "What is BABYLIGER COIN?",
        answer: "Babyliger is India's first meme coin registered in Central Vigilance Commission of India."
    },
    {
        question: "Where can I buy Babyliger?",
        answer: `You can buy Babyliger coin exclusively on our official website: 
                Please refer to detailed instructions on our website to know more. ( babyliger.vip ) is the only official website for buying Babyliger coins. All other sellers are a scam.`
    },
    {
        question: "What is the use-case of Babyliger?",
        answer: `With Babyliger coins, the investors have an opportunity to buy official merchandise like Babyliger clothing soon.There are plans of expansion and we will be launching play to earn games where the community can participate and win coins by playing games.`
    },
    {
        question: "Can we stake Babyliger?",
        answer: `Yes you can stake Babyliger on our website soon.`
    }
]


export const presaleData = [
    { round: 'Round 1', supply: '40,00,00,00,000', price: '0.0000018966989555096', total: '75868$' },
    { round: 'Round 2', supply: '40,00,00,00,000', price: '0.00000201050089284018', total: '80420$' },
    { round: 'Round 3', supply: '40,00,00,00,000', price: '0.00000213113094641059', total: '85245$' },
    { round: 'Round 4', supply: '40,00,00,00,000', price: '0.00000225899880319522', total: '90360$' },
    { round: 'Round 5', supply: '40,00,00,00,000', price: '0.00000239453873138694', total: '95782$' },
    { round: 'Round 6', supply: '40,00,00,00,000', price: '0.00000253821105527015', total: '101528$' },
    { round: 'Round 7', supply: '40,00,00,00,000', price: '0.00000269050371858636', total: '107620$' },
    { round: 'Round 8', supply: '40,00,00,00,000', price: '0.00000285193394170154', total: '114077$' },
    { round: 'Round 9', supply: '40,00,00,00,000', price: '0.00000302304997820364', total: '120922$' },
    { round: 'Round 10', supply: '40,00,00,00,000', price: '0.00000320443297689585', total: '128177$' },
];

export const presaleTotalData = { round: 'Total', supply: '4,00,00,00,00,000', price: '', total: '1000000$' };



export const DEFAULT_RPC = process.env.REACT_APP_DEFAULT_RPC;
export const DEFAULT_CHAIN = process.env.REACT_APP_DEFAULT_CHAIN;
export const MULTICALL = process.env.REACT_APP_MULTICALL;
export const TOKEN_ADDRESS = process.env.REACT_APP_TOKEN_ADDRESS;
export const EXPLORE_URL = process.env.REACT_APP_EXPLORE_URL;
export const TOKEN_SYMBOL = process.env.REACT_APP_TOKEN_SYMBOL;
export const TOKEN_DECIMALS = process.env.REACT_APP_TOKEN_DECIMALS;
export const CURRENCY_SYMBOL = process.env.REACT_APP_CURRENCY_SYMBOL;
export const PRESALE_ADDRESS = process.env.REACT_APP_PRESALE_ADDRESS;
export const chains = [bsc]
export const BASE_URL = "https://babyliger.vip"


export const BUY_CURRENCY = [
    {
        address : "0x0000000000000000000000000000000000000000",
        name : "Binanace Coin",
        symbol : "BNB",
        image : bnbImg,
        decimals : 18
    },
    {
        address : "0x55d398326f99059fF775485246999027B3197955",
        name : "Tether USD",
        symbol : "USDT",
        image : usdtImg,
        decimals : 18
    }
]