import { BASE_URL } from '../helper/constant';

function Whitepaper() {
    return (
        <>
            <div className="presale-section" id="whitepaper">
                <div className="container">
                    <div className="row">
                        <div className="col-smp-12 col-sm-12 col-lg-12">
                            <div className='text-center'>
                                <h2 className='section-title mb-5'><span>BABYLIGER</span> Whitepaper</h2>
                            </div>
                        </div>
                    </div>
                    <div className='align-items-center row'>
                        <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                            <p>
                                The BABYLIGER mission is to create a platform to make Airdrops accessible to everyone. We create a user-friendly and easy way to participate in Airdrops. You don't have to be a Crypto-Nerd to understand and benefit from airdrops. We are unique and we are the future! Read the whitepaper for more information.
                            </p>
                            <a href={`${BASE_URL}/WhitePaper.pdf`} target='_blank' rel="noreferrer" className='btn btn-primary me-3'>Download Now</a>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 mt-5 mt-md-0">
                            <div className="img-animate text-center">
                                <img src={require('../Assets/img/whitepaper.png')} className='img-fluid' alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="presale-section" id="legality">
                <div className="container">
                    <div className="row">
                        <div className="col-smp-12 col-sm-12 col-lg-12">
                            <div className='text-center'>
                                <h2 className='section-title mb-5'><span>CVC</span> CERTIFICATION</h2>
                            </div>
                        </div>
                    </div>
                    <div className='align-items-center row'>
                        <div className="col-sm-12 col-md-6 col-lg-6 mt-5 mt-md-0">
                            <div className="img-animate text-center">
                                <img src={require('../Assets/img/cvc.png')} className='img-fluid' alt="" />
                            </div>
                        </div>
                        <div className="col-sm-12 col-md-6 col-lg-6 text-center">
                            <p>

                                CVC certification in the context of the Indian government refers to compliance with the guidelines and regulations set by the Central Vigilance Commission (CVC) to prevent and address corruption in government operations. This includes vigilance clearance for government officials to ensure they are not involved in corruption before promotions or appointments, adherence to Integrity Pacts by vendors and contractors in government procurements, and overall adherence to anti-corruption practices. The CVC ensures transparency and integrity in governmental processes by conducting inquiries, recommending actions, and supervising vigilance activities across various organizations.
                            </p>
                            <a href={`${BASE_URL}/cvccertificate.pdf`} target='_blank' rel="noreferrer" className='btn btn-primary me-3'>Download Now</a>
                        </div>

                    </div>
                </div>
            </div>

            <div className="presale-section">
                <div className="container">
                    <div className="row">
                        <div className="col-smp-12 col-sm-12 col-lg-12">
                            <div className='text-center'>
                                <h2 className='section-title mb-2 mb-md-5'><span>CHESS</span></h2>
                            </div>
                        </div>
                    </div>
                    <div className='align-items-center row'>
                        <div className="col-sm-12 mt-5 mt-md-0">
                            <div className="img-animate text-center">
                                <img src={require('../Assets/img/game.jpeg')} className='img-fluid' alt="logo" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Whitepaper;