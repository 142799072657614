import { getMultiCall, getWeb3Contract } from '../helper/contractHelper';
import { PRESALE_ADDRESS } from '../helper/constant';
import { useEffect, useState } from 'react';
import presaleAbi from '../abis/presale.json';
import { toast } from 'react-toastify';



export const useCommonStats = (updator) => {
    
    const [stats, setStats] = useState({
        liveStageId : 0,
        nextStageId : 0,
        saleStatus : false,
        claimStatus : false,
        levelRate : 0,
        stages : [],
        nextstage : [],
        totalPer : 0,
        loading : true
    });

    let presaleContract = getWeb3Contract(presaleAbi, PRESALE_ADDRESS);
    useEffect(() => {
        const fetch = async () => {
            try {
                setStats({
                    ...stats,
                    loading : true
                })
                
                let data = await getMultiCall([
                    presaleContract.methods.liveStageId(), //0
                    presaleContract.methods.nextStageId(), //1
                    presaleContract.methods.saleStatus(), //2
                    presaleContract.methods.claimStatus(), //3
                    presaleContract.methods.levelRate(0), //4
                ])

                let stageData = await getMultiCall([
                    presaleContract.methods._stages(data[0]),
                    presaleContract.methods._stages(parseInt(data[0]) + 1),
                ])
               
                setStats({
                    liveStageId : data[0],
                    nextStageId : data[1],
                    saleStatus : data[2],
                    claimStatus : data[3],
                    levelRate : data[4],
                    stages : stageData[0],
                    nextstage : stageData[1], 
                    totalPer : parseFloat((stageData[0][4] / stageData[0][3]) * 100).toFixed(2),
                    loading : false
                })
            }
            catch (err) {
                toast.error(err.reason ? err.reason : err.message);
            }
        }


        fetch()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updator])

    return stats;
}