

function Roadmap() {
    return (
        <>
            <div className="roadmap-section" id="roadmap">
                <div class="timeline-container">
                    <div className='text-center'>
                        <h2 className='section-title mb-5'><span>Roadmap</span></h2>
                    </div>
                    {/* <h1 class="roadmap-title">Roadmap</h1> */}
                    <div id="timeline">

                        <div class="timeline-item">
                            <div class="timeline-icon">
                                <img src={require('../Assets/img/logo.png')} alt="" />
                            </div>
                            <div class="timeline-content">
                                <h2>Phase 1</h2>
                                <ul>
                                    <li>Socials Launch</li>
                                    <li>Website Launch</li>
                                    <li>Development (Contract)</li>
                                    <li>Registration on CVC(Legality)</li>
                                    <li>Presale</li>
                                    <li>Launch on Pancake</li>
                                    <li>Burning Event</li>
                                </ul>

                            </div>
                        </div>

                        <div class="timeline-item">
                            <div class="timeline-icon">
                                <img src={require('../Assets/img/logo.png')} alt="" />
                            </div>
                            <div class="timeline-content right">
                                <h2>Phase 2</h2>
                                <ul>
                                    <li>Coinmarketcap Listing</li>
                                    <li>New Website Interface</li>
                                    <li>Online Shop Launch</li>
                                    <li>Big Marketing Campaign</li>
                                    <li>Airdrops & Giveaways</li>
                                    <li>Burning Event</li>
                                </ul>

                            </div>
                        </div>

                        <div class="timeline-item">
                            <div class="timeline-icon">
                                <img src={require('../Assets/img/logo.png')} alt="" />
                            </div>
                            <div class="timeline-content">
                                <h2>Phase 3</h2>
                                <ul>
                                    <li>Coingecko Listing</li>
                                    <li>10000 Holders</li>
                                    <li>Staking Pools</li>
                                    <li>P2E Game (CHESS)</li>
                                    <li>New Website Interface</li>
                                    <li>Burning Event</li>
                                </ul>
                            </div>
                        </div>

                        <div class="timeline-item">
                            <div class="timeline-icon">
                                <img src={require('../Assets/img/logo.png')} alt="" />
                            </div>
                            <div class="timeline-content right">
                                <h2>Phase 4</h2>
                                <ul>
                                    <li>Airdrop 2.0</li>
                                    <li>Partnership with Big Influencers</li>
                                    <li>Big Marketing Campaign</li>
                                    <li>25000 Holders</li>
                                    <li>Tier 2 Exchange Listing</li>
                                    <li>Burning Event</li>
                                </ul>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}
export default Roadmap