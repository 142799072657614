import React from 'react'
import Roadmap from '../Components/Roadmap';
import Buy from '../Components/Buy';
import Tokonomics from '..//Components/Tokenomics';
import Presale from '../Components/Presale';
import Whitepaper from '../Components/Whitepaper';
import Faq from '../Components/Faq';
import HeroSection from '../Components/HeroSection';

export default function Home() {
    return (
        <>
            <HeroSection />
            <Buy />
            <Tokonomics />
            <Roadmap />
            <Presale />
            <Whitepaper />
            <Faq />
        </>
    )
}
