import React, { useState } from 'react';
import { CopyToClipboard } from "react-copy-to-clipboard";
import emailjs from 'emailjs-com';
import { toast } from 'react-toastify';
import { useAccount } from 'wagmi';
export default function UpiModal({ show, setShow }) {
    const [refcopy, setRefcopy] = useState(false);
    const {address } = useAccount();

    const handleSubmit = (e) => {
        if(!address){
            toast.error('Please connect wallet');
            return false;
        }
        e.preventDefault();

        const formData = {
            amount: e.target.amount.value,
            your_upi: e.target.your_upi.value,
            upi_holder_name: e.target.upi_holder_name.value,
            ref_id: e.target.ref_id.value,
            wallet_address : address
        };

        emailjs.send(process.env.REACT_APP_SERVICE_ID, process.env.REACT_APP_TEMPLATE_ID, formData , process.env.REACT_APP_USER_ID)
        .then((response) => {
            toast.success('successfully saved records!', response.status, response.text);
        }).catch((err) => {
            console.log(err.message)
            console.log('Failed to send email:', err);
        });

        setShow(false); // Close the modal after submission
    };

    return (
        <div className={`modal fade ${show ? 'show' : ''}`} style={{ display: show ? "block" : "none" }} id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered">
                <div className="modal-content">
                    <div className="modal-header">
                        <h1 className="modal-title fs-5" id="exampleModalLabel">UPI Payments</h1>
                        <button type="button" onClick={() => setShow(false)} className="btn-close text-white" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div className="modal-body">
                        <form onSubmit={handleSubmit}>
                            <p className='mb-0'>Name : Kishan Kumar</p>
                            <p className='mb-2'>
                                UPI : sahukishan00d-1@okaxis
                                <CopyToClipboard text="sahukishan00d-1@okaxis" onCopy={() => {
                                    setRefcopy(true);
                                    setTimeout(() => {
                                        setRefcopy(false);
                                    }, 2000)
                                }}>
                                    <button className="btn" type="button" id="button-addon2">
                                        {!refcopy ? (
                                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="#fff" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><rect x="9" y="9" width="13" height="13" rx="2" ry="2"></rect><path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1"></path></svg>
                                        ) : (
                                            <svg viewBox="0 0 24 24" width="20" height="20" stroke="#fff" strokeWidth="2" fill="none" strokeLinecap="round" strokeLinejoin="round" className="css-i6dzq1"><path d="M16 4h2a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H6a2 2 0 0 1-2-2V6a2 2 0 0 1 2-2h2"></path><rect x="8" y="2" width="8" height="4" rx="1" ry="1"></rect></svg>
                                        )}
                                    </button>
                                </CopyToClipboard>
                            </p>
                            <div className="mb-3">
                                <label htmlFor="amount" className="form-label">Enter Amount:</label>
                                <input type="text" name="amount" className="form-control" id="amount" placeholder="eg.10000" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="your_upi" className="form-label">Your UPI ID:</label>
                                <input type="text" name="your_upi" className="form-control" id="your_upi" placeholder="eg.999995555@ybl" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="upi_holder_name" className="form-label">UPI Holder Name:</label>
                                <input type="text" name="upi_holder_name" className="form-control" id="upi_holder_name" placeholder="eg.Akshay Kumar" required />
                            </div>
                            <div className="mb-3">
                                <label htmlFor="ref_id" className="form-label">UTR / Reference No / UPI Ref. No:</label>
                                <input type="text" name="ref_id" className="form-control" id="ref_id" placeholder="eg.Xfdtag1223cgch" required />
                            </div>
                            <div className="mb-3 text-center">
                                <button type="submit" className="btn btn-primary">Submit</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}
