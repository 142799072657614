import * as React from 'react'
// import { providers } from 'ethers'
import {  useSigner } from 'wagmi';


 
// export function walletClientToSigner(walletClient) {
//   const { account, chain, transport } = walletClient
//   const network = {
//     chainId: chain.id,
//     name: chain.name,
//     ensAddress: chain.contracts?.ensRegistry?.address,
//   }
//   const provider = new providers.Web3Provider(transport, network)
//   const signer = provider.getSigner(account.address)
//   return signer
// }
 
/** Hook to convert a viem Wallet Client to an ethers.js Signer. */
export function useEthersSigner() {
  const { data: signer } = useSigner();
  return React.useMemo(
    () => signer,
    [signer],
  )
}